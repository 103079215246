import React, { memo } from 'react';
import { withTranslation } from 'react-i18next';
import { Text } from '@ticketmaster/aurora';
import { getAdditionalInfos } from '@/helpers/additionalInfo/additionalInfoHelper';

import { T } from '@/helpers/i18n';

import './additionalInfo.scss';

type Props = {
  title: string;
  ticket: TicketModel;
  countryCode: String;
} & T;

const AdditionalInfo = ({ t, title = '', ticket }: Props) => {
  if (!ticket?.additionalInfos) {
    return null;
  }
  const additionalInfo = getAdditionalInfos(ticket, false);
  if (additionalInfo.length) {
    return (
      <div className="info-additional-info">
        <Text weight="semiBold">{t(title)}</Text>
        {additionalInfo}
      </div>
    );
  }
  return null;
};

export default withTranslation('translation')(memo(AdditionalInfo));