import React, { memo } from 'react';
import { withTranslation } from 'react-i18next';
import { Text } from '@ticketmaster/aurora';
import SlickArrow from '@/partials/arrow/arrow';

import { T } from '@/helpers/i18n';

import './pagination.scss';

type Props = {
  total: number;
  index: number;
  next: () => void;
  prev: () => void;
} & T;

const TicketCount = memo(({ t, index, total, next, prev }: Props) => (
  <section className="pagination">
    <SlickArrow disabled={index === 0} direction="left" onClick={prev}/>
    <Text weight="semiBold" className="ticket-counter-text">
      {t('WALLET.TICKETS.COUNTER_TEXT', { index: index + 1, total })}
    </Text>
    <SlickArrow disabled={index + 1 === total} onClick={next}/>
  </section>
));

export default withTranslation('translation')(TicketCount);
