// @ts-nocheck

export const config = Object.assign(
  {
    // The line below is for tests, when we create the presto client this needs to be in that format or it will fail.
    REACT_APP_API_URL: 'http://localhost:8080'
  },
  process.env,
  window.globalConfig
);

export default config;
