import React, { Component } from 'react';
import { getPresence } from './RET.service';
import { withTranslation } from 'react-i18next';
import { Text } from '@ticketmaster/aurora';
import './RET.scss';
import { T } from '@/helpers/i18n';

type Props = {
  token: string;
  id: string;
} & T;
type State = {};

class RET extends Component<Props, State> {
  state: { renderer: any } = {
    renderer: null
  };

  async componentDidMount() {
    const { token, id } = this.props;
    const Presence = await getPresence();
    const renderer = new Presence.SecureEntryView({ selector: `#${id}` });
    renderer.setPDF417Subtitle(false);
    renderer.setToken(token);

    this.setState({
      renderer,
    });
  }

  componentDidUpdate(prev: { token: string; }) {
    const { renderer } = this.state;
    const { token } = this.props;
    if (renderer && token !== prev.token) {
      renderer.setToken(token);
    }
  }

  render() {
    const { id, t } = this.props;
    return (
      <section>
        <div id={id} className="ret-barcode-container"/>
        {<Text>{t('WALLET.TICKETS.SCREENSHOTS_WILL_NOT_GET_YOU_IN')}</Text>}
      </section>
    );
  }
}

export default withTranslation('translation')(RET);
