// @ts-nocheck

import React, { useContext } from 'react';
import { withTranslation } from 'react-i18next';
import { BackIcon, NavBar, Text } from '@ticketmaster/aurora';
import { HEADER_TITLE } from './header.constants';
import { RootContext } from '../root/root.provider';

import './header.scss';
import type { T } from '../helpers/i18n';

export const AppHeader = ({ t }: T) => {
  const context = useContext(RootContext);
  return (
    <header className="header">
      <NavBar className={'nav-bar'}>
        <div className="navbar-logo">
          <Text
            weight="semiBold"
            size="zetta"
            style={{ color: '#fff', fontStyle: 'italic' }}
            onClick={() => context.setInfo(null)}
          >
            {!context.state.info && 't'}
            {!!context.state.info && (
              <span className="back-icon">
                <BackIcon size="large" color="white"/>
              </span>
            )}
          </Text>
        </div>

        <div className="navbar-text">
          <Text size="kilo" weight="semiBold" style={{ color: '#fff' }}>
            {!context.state.info && t(HEADER_TITLE)}
            {!!context.state.info && t('HEADER.INFO')}
          </Text>
        </div>
      </NavBar>
    </header>
  );
};

export default withTranslation('translation')(AppHeader);
