import { Text } from '@ticketmaster/aurora';
import React from 'react';

function formatAdditionalInfo(data: any, isFrontOfTicket: boolean, isLast: boolean) {
  return (
    <div key={data.key}>
      <Text style={{ wordBreak: 'break-word' }}>
        {data.value}
      </Text>
      {!isFrontOfTicket && isLast && <hr className="divider"/>}
    </div>
  );
}

export function getAdditionalInfos(ticket: TicketModel, isFrontOfTicket: boolean) {
  const infos = isFrontOfTicket
    ? ticket.additionalInfos.filter((data: any) => data.showInFront)
    : ticket.additionalInfos.filter((data: any) => data.showInBack);

  const formatInfos = (infos: any[], isFront: boolean) => {
    return infos.map((data: any, index: number) => {
      if (data.value) {
        const isLast = index === infos.length - 1;
        return formatAdditionalInfo(data, isFront, isLast);
      }
      return null;
    }).filter(Boolean);
  };

  return formatInfos(infos, isFrontOfTicket);
}