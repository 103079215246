export enum RedemptionDisabledReasons {
  REGION_DISABLED = 'REGION_DISABLED',
  BOX_OFFICE_ONLY = 'BOX_OFFICE_ONLY',
  ARCHTICS_EVENT = 'ARCHTICS_EVENT',
  ABOVE_MAX_TICKET_LIMIT = 'ABOVE_MAX_TICKET_LIMIT',
  FORCED = 'FORCED',
}

export function getRedemptionReasonByCode(reason: string) {
  switch (reason) {
    case RedemptionDisabledReasons.REGION_DISABLED:
      return 'WALLET.TICKETS.NOT_AVAILABLE';
    case RedemptionDisabledReasons.BOX_OFFICE_ONLY:
      return 'WALLET.TICKETS.NO_BOX_OFFICE_ONLY_EVENT';
    case RedemptionDisabledReasons.ABOVE_MAX_TICKET_LIMIT:
      return 'WALLET.TICKETS.NOT_ALLOW_MORE_THAN_FIFTY_TICKETS';
    default:
      return 'WALLET.MANAGE.ADD_TO_ACCOUNT.CANNOT_REDEEM';
  }
}
