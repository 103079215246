import { FIELDS_BG_COLOR_HEX, FONT_COLOR_HEX, HEADER_BG_COLOR_HEX } from '@/wallet/tickets/ticket/fields/field/field.constants';
import { BARCODE_BG_COLOR_HEX, BARCODE_BORDER_COLOR_HEX, TICKET_BG_COLOR_HEX, VOIDED_BG_COLOR_HEX, VOIDED_TEXT_COLOR_HEX } from '@/wallet/wallet.constants';

export function getFieldBackgroundColor(ticket: TicketModel) {
  if (ticket.voidedAt != null) {
    return VOIDED_BG_COLOR_HEX;
  }
  if (ticket.bgColorHex != null) {
    return ticket.bgColorHex;
  }
  return FIELDS_BG_COLOR_HEX;
}

export function getHeaderBackgroundColor(ticket: TicketModel) {
  if (ticket.voidedAt != null) {
    return VOIDED_BG_COLOR_HEX;
  }
  if (ticket.bgColorHex != null) {
    return ticket.bgColorHex;
  }
  return HEADER_BG_COLOR_HEX;
}

export function getBarcodeBackgroundColor(ticket: TicketModel) {
  if (ticket.voidedAt != null) {
    return VOIDED_BG_COLOR_HEX;
  }
  return BARCODE_BG_COLOR_HEX;
}

export function getTicketBackgroundColor(ticket: TicketModel) {
  if (ticket.voidedAt != null) {
    return VOIDED_BG_COLOR_HEX;
  }
  return TICKET_BG_COLOR_HEX;
}

export function getBarcodeBorderColor(ticket: TicketModel) {
  if (ticket.voidedAt != null) {
    return VOIDED_BG_COLOR_HEX;
  }
  return BARCODE_BORDER_COLOR_HEX;
}

export function getTextFontColor(ticket: TicketModel) {
  if (ticket.voidedAt != null) {
    return VOIDED_TEXT_COLOR_HEX;
  }
  if (ticket.fontColorHex != null) {
    return ticket.fontColorHex;
  }
  return FONT_COLOR_HEX;
}

export function getEventTitleColor(ticket: TicketModel) {
  if (ticket.voidedAt != null) {
    return VOIDED_TEXT_COLOR_HEX;
  }
  return '';
}