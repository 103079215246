// @ts-nocheck

import React from 'react';

import './barcode.scss';
import { getBarcodeBorderColor, getTicketBackgroundColor } from '@/helpers/ticketColors/ticketColorsHelper';
import NoOrVoidedBarcode from './noOrVoidedBarcode';
import RotatingOrStaticBarcode from './rotatingOrStaticBarcode';

type Props = {
  activeToken: string;
  ticket: TicketModel;
  event: EventModel;
  index: number;
};

const Barcode = ({ ticket, activeToken, index }: Props) => {
  const { shouldNotShowBarcode } = ticket;
  const shouldShowBarcodeClassName = shouldNotShowBarcode ? 'ticket-barcode_link-tickets' : 'ticket-barcode_barcode';
  const ticketBarcodeClassNames = `ticket-barcode ${shouldShowBarcodeClassName}`;

  const outerBarcodeBackgroundColor = getTicketBackgroundColor(ticket);
  const barcodeBorderColor = getBarcodeBorderColor(ticket);

  function renderBarcodeComponent() {
    if (ticket.voidedAt != null || shouldNotShowBarcode) {
      return <NoOrVoidedBarcode activeToken={activeToken} ticket={ticket}/>;
    }
    return <RotatingOrStaticBarcode ticket={ticket} index={index}/>;
  }

  return (
    <section className={ticketBarcodeClassNames} style={{ backgroundColor: outerBarcodeBackgroundColor, borderColor: barcodeBorderColor }}>
      {renderBarcodeComponent()}
    </section>
  );
};

export default Barcode;