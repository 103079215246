// @ts-nocheck
import React from 'react';
import { ChevronIcon } from '@ticketmaster/aurora';
import './arrow.scss';

const formatClassName = (direction, disabled) => `slick-arrow ${direction}${disabled ? ' disabled' : ''}`;

const SlickArrow = ({ onClick, direction = 'right', disabled = false }) => (
  <div className={formatClassName(direction, disabled)}>
    <ChevronIcon
      size={15}
      color={disabled ? '#bababa' : '#514e4e'}
      direction={direction}
      onClick={!disabled ? onClick : undefined}
    />
  </div>
);

export default SlickArrow;
