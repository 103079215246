import { Text } from '@ticketmaster/aurora';
import React, { memo } from 'react';
import { withTranslation } from 'react-i18next';
import { VOIDED_TEXT_COLOR_HEX } from '@/wallet/wallet.constants';
import { T } from '@/helpers/i18n';

const VoidedBarcode = ({ t }: T) => {
  return (
    <div id="voided-barcode">
      <Text className="text-block" size="kilo" style={{ color: VOIDED_TEXT_COLOR_HEX }}>
        {t('WALLET.TICKETS.TICKET.INVALID_TICKET')}
        <br/>
        <br/>
        {t('WALLET.TICKETS.TICKET.INVALID_TICKET_FAN_SUPPORT')}
      </Text>
    </div>
  );
};

export default withTranslation('translation')(memo(VoidedBarcode));