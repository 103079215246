import {DateTime} from "luxon";
import i18next from 'i18next';

export function getEventStartLocalDateTime(startLocalDate: string, startLocalTime: string | undefined = undefined): string {
  const language = i18next.language;
  let date: DateTime;
  let dateFormat;

  if (startLocalTime) {
    date = DateTime.fromSQL(startLocalDate + ' ' + startLocalTime);
    dateFormat = DateTime.DATETIME_MED_WITH_WEEKDAY;
  } else {
    date = DateTime.fromISO(startLocalDate);
    dateFormat = DateTime.DATE_MED_WITH_WEEKDAY;
  }

  return capitalizeFirstLetter(date.toLocaleString(dateFormat, { locale: language }));
}

export function getEventDateRange(dateRange: string) {
  const formattedDates = dateRange.split(' - ').map((eventDate) => getEventDate(eventDate));

  return formattedDates.join(' - ');
}

function getEventDate(eventDate: string): string {
  const language = i18next.language;
  let date = DateTime.fromSQL(eventDate);

  return capitalizeFirstLetter(date.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY , { locale: language }));
}

function capitalizeFirstLetter(text: string): string {
  return text.charAt(0).toUpperCase() + text.slice(1);
}
