const scriptUrl = 'https://secure-entry.ticketmaster.com/presence-secure-entry.min.js';

let presenceScriptLoadedPromise: Promise<any>;
export const getPresence = () => {
  if (!presenceScriptLoadedPromise) {
    presenceScriptLoadedPromise = new Promise<any>((resolve, reject) => {
      try {
        const script = document.createElement('script');
        script.src = scriptUrl;
        script.async = true;
        script.onload = () => resolve((window as any).Presence);
        if (document && document.body) document.body.appendChild(script);
      } catch (err) {
        reject(err);
      }
    });
  }

  return presenceScriptLoadedPromise;
};
