// @ts-nocheck
import React, { Component } from 'react';

import TicketHeader from './header/header';
import TicketFields from './fields/fields';
import TicketEvent from './event/event';
import TicketAdditionalInfo from './additionalInfo/ticketAdditionalInfo';
import TicketBarcode from './barcode/barcode';

import './ticket.scss';
import { getTicketBackgroundColor } from '@/helpers/ticketColors/ticketColorsHelper';
import { EventModel } from '@/models/eventModel';

type Props = {
  activeToken: string;
  ticket: TicketModel;
  index: number;
  event: EventModel;
};

type State = any;

class Ticket extends Component<Props, State> {
  render() {
    const { ticket, event, activeToken, index } = this.props;
    const ticketBackgroundColor = getTicketBackgroundColor(ticket);

    return (
      <section className="ticket" style={{ backgroundColor:  ticketBackgroundColor }}>
        <TicketHeader ticket={ticket} event={event} />
        <TicketFields ticket={ticket} />
        <TicketEvent event={event} ticket={ticket} />
        <TicketBarcode activeToken={activeToken} ticket={ticket} index={index} event={event} />
        <TicketAdditionalInfo ticket={ticket} />
      </section>
    );
  }
}

export default Ticket;
