import React from 'react';
import { Text } from '@ticketmaster/aurora';
import { withTranslation } from 'react-i18next';

import './field.scss';
import { T } from '@/helpers/i18n';

type Props = {
  title: string;
  value: object | string,
  textFontColor: string,
} & T;

const TicketField = ({ t, title, value, textFontColor }: Props) => {

  if (!title && !value) {
    return null;
  }

  const style = { color: textFontColor, textAlign: 'center' };

  const formatFieldTitle = (title: string) => {
    if (!title) {
      return '';
    }
    return t(`WALLET.TICKETS.TICKET.${title.toUpperCase()}`);
  };

  const formatFieldValue = (value: object | string) => {
    if (!value) {
      return '-';
    }
    if (Array.isArray(value)) {
      return value.join('-');
    }
    return value;
  };

  return (
    <div className="ticket-field">
      <div className="ticket-field-title">
        <Text style={style} size="hecto">
          {formatFieldTitle(title)}
        </Text>
      </div>
      <div className="ticket-field-value">
        <Text style={style} size="tera" weight="semiBold">
          {formatFieldValue(value)}
        </Text>
      </div>
    </div>
  );
};

export default withTranslation('translation')(TicketField);
