import i18next from 'i18next';
import detector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';

import en from './en/translation.json';
import fr from './fr/translation.json';
import es from './es/translation.json';

function flattenJSON(json : any, key="") {
  let flatten : {[index: string]: any} = {};
  for(const prop in json){
    if(typeof json[prop]=='object'){
      const formatedKey = key === '' ? prop : key + '.' + prop;
      flatten = {...flatten, ...flattenJSON(json[prop], formatedKey)}
    }else{
      flatten[key] = json[prop]
    }
  }

  return flatten
}

const i18nOptions = {
  interpolation: { escapeValue: false },
  //lng: 'en',
  fallbackLng: 'en',
  resources: {
    us: {translation: flattenJSON(en)},
    en: {translation: flattenJSON(en)},
    fr: {translation: flattenJSON(fr)},
    es: {translation: flattenJSON(es)},
  },
  detection: {
    order: ['querystring', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
    lookupQuerystring: 'lng',
  },
};

i18next.use(detector).use(initReactI18next).init(i18nOptions);

export default i18next;

export type T = {
  t: (str: string, args?: any) => string;
};
