// @ts-nocheck
import React, { memo, useContext } from 'react';
import { withTranslation } from 'react-i18next';
import { Text, InfoIcon } from '@ticketmaster/aurora';

import { RootContext } from '@/root/root.provider';
import { T } from '@/helpers/i18n';

import './header.scss';
import { getHeaderBackgroundColor, getTextFontColor } from '@/helpers/ticketColors/ticketColorsHelper';
import { getBestDescription } from '@/models/ticketModel.ts';

type Props = {
  ticket: TicketModel;
} & T;

const TicketHeader = ({ t, ticket }: Props) => {
  const rootContext = useContext(RootContext);
  const headerBackgroundColor = getHeaderBackgroundColor(ticket);
  const textFontColor = getTextFontColor(ticket);

  return (
    <section className="ticket-header" style={{ backgroundColor: headerBackgroundColor }}>
      <div className="ticket-header-title">
        <Text style={{ color: textFontColor || 'inherit' }} size="hecto">
          {getBestDescription(ticket) || t('WALLET.TICKETS.DESCRIPTION')}
        </Text>
      </div>

      <div className="ticket-header-circle-eye" onClick={() => rootContext.setInfo(ticket.id)}>
        <InfoIcon color={ textFontColor } size="regular" />
      </div>
    </section>
  );
};

export default withTranslation('translation')(memo(TicketHeader));
