import React, { Fragment } from 'react';

import './rotatingLine.scss';

const RotatingLine = () => {
  return (
    <Fragment>
      <div className="animated first"/>
      <div className="animated second"/>
    </Fragment>
  );
};

export default RotatingLine;
