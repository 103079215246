// @ts-nocheck

import React, { Component, Fragment } from 'react';
import { RootContext, StateContext } from '../root/root.provider';
import Tickets from './tickets/tickets';
import TicketsRedeemed from './tickets/ticketsRedeemed';
import Info from './info/info';
import Banner from '../partials/banner/banner';
import { getEnvelope, redeemTickets } from './envelope/envelope.service';
import walletBannerOpts from './wallet.banners';
import { getRedemptionReasonByCode } from './manage/redemption/redemptionDisabledReasons';

import './wallet.scss';
import i18next from 'i18next';
import { EnvelopeModel } from '@/models/envelopeModel';

type State = {
  envelope: EnvelopeModel
};

class Wallet extends Component<State> {
  static contextType: React.Context<StateContext> = RootContext;
  static token: string = window.location.pathname.substring(1);

  state: State = {
    envelope: {},
  }

  async componentDidMount() {
    if (!Wallet.token) {
      return this.context.showBanner(walletBannerOpts.noToken());
    }
    try {
      const result = await getEnvelope(Wallet.token);
      await i18next.changeLanguage(result.data.userLanguage);
      if (!result.data.tickets.length) {
        return this.context.showBanner(walletBannerOpts.noTickets());
      }
      if (result.data.shouldRedirectToRedemption) {
        this.context.showBanner(await redeemTickets(result.data.activeToken));
      } else {
        this.setState({ ...this.state, envelope: result.data });
      }
    } catch (err: any) {
      const { status, tmOrdersUrl, message } = err.validate;
      switch (status) {
        case 'notReady':
          this.context.showBanner(walletBannerOpts.notReady());
          break;
        case 'alreadyRedeemed':
          this.context.alreadyRedeemed(true);
          this.context.setTmOrdersUrl(tmOrdersUrl);
          break;
        case 'defaultError':
          this.context.showBanner(walletBannerOpts.defaultError());
          break;
        case 'redemptionError':
          this.context.showBanner(walletBannerOpts.redemptionError(getRedemptionReasonByCode(message)));
          break;
        default:
          this.context.showBanner(walletBannerOpts.defaultError());
          break;
      }
    }
  }

  render() {
    const { envelope } = this.state;

    const { tickets, redemptionAvailability } = envelope;
    const { info, banner, alreadyRedeemed } = this.context.state;

    return (
      <div className={`wallet${!!info ? ' info-opened' : ''}`}>
        <Banner config={banner}/>

        {alreadyRedeemed && <TicketsRedeemed/>}

        {!alreadyRedeemed && !!tickets && !!tickets.length && (
          <Fragment>
            {<Tickets envelope={envelope} hidden={!!info} redemptionAvailability={redemptionAvailability}/>}
            {!!info && <Info active={info} envelope={envelope}/>}
          </Fragment>
        )}
      </div>
    );
  }
}

export default Wallet;
