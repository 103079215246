// @ts-nocheck
//@ flow

import React, { useContext } from 'react';
import { withTranslation } from 'react-i18next';
import { Banner } from '@ticketmaster/aurora';
import { RootContext } from '@/root/root.provider';

import { getExpandedText, getCollapsedText } from './banner.service';

import './banner.scss';

const BannerBlock = ({ t, config }: any) => {
  const rootContext = useContext(RootContext);
  if (!config) return null;

  if (config.ttl && typeof config.ttl === 'number') setTimeout(() => rootContext.showBanner(null), config.ttl);

  return (
    <Banner
      heading={t(config.heading || undefined)}
      content={t(config.content || undefined)}
      expandedText={t(getExpandedText(config))}
      collapsedText={t(getCollapsedText(config))}
      onButtonClick={config.onButtonClick}
      isOpen
      onRequestClose={null !== config.onRequestClose ? () => rootContext.showBanner(null) : undefined}
      variant={config.variant || undefined}
      icon={config.icon || null}
      isExpanded={config.isExpanded || false}
    />
  );
};

export default withTranslation('translation')(BannerBlock);
