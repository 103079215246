import config from '@/config';

export const updateTitle = () => {
  switch (config.REACT_APP_NODE_ENV) {
    case 'production':
      break;
    case 'stage':
      document.title = `DEMO - ${document.title}`;
      break;
    case 'development':
      document.title = `TEST - ${document.title}`;
      break;
    case 'local':
    default:
      document.title = `LOCAL - ${document.title}`;
  }
};
