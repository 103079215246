// @ts-nocheck

import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Slider from 'react-slick';

import Ticket from './ticket/ticket.tsx';
import Pagination from './pagianation/pagination.tsx';
import { T } from '@/helpers/i18n';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './tickets.scss';

import Footer from './ticket/footer/footer';
import _ from 'lodash';

type Props = {
  envelope: EnvelopeModel;
  hidden: boolean;
  redemptionAvailability: RedemptionAvailabilityModel;
} & T;
type State = {
  isLandscape: boolean
};

function orderTickets(tickets: TicketModel[]) {
  return _.orderBy(tickets, 'voidedAt', 'desc');
}

export class Tickets extends Component<Props, State> {
  slider: any;
  state = {
    isLandscape: window.innerWidth > window.innerHeight,
  };

  componentDidMount() {
    window.addEventListener('orientationchange', () => {
      const isLandscape = window.innerWidth > window.innerHeight;
      if (isLandscape !== this.state.isLandscape) {
        this.setState({ isLandscape });
      }
    });
  }

  render() {
    const { envelope, hidden, redemptionAvailability } = this.props;
    const { tickets, event, activeToken, tmOrderUrl } = envelope;
    const { isLandscape } = this.state;

    const settings = {
      className: 'tickets-slider',
      dots: false,
      infinite: false,
      speed: 500,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      centerMode: true,
      centerPadding: '24px',
    };

    return (
      <section className="tickets">
        <div className={`tickets${hidden ? ' hidden' : ''}${isLandscape ? ' landscape' : ''}`}>
          <Slider ref={(c) => (this.slider = c)} {...settings}>
            {orderTickets(tickets).map((ticket, index) => (
              <div className="ticket-pagination" key={index}>
                <Ticket activeToken={activeToken} ticket={ticket} event={event} index={index}/>
                <Pagination
                  index={index}
                  total={tickets.length}
                  next={() => this.slider.slickNext()}
                  prev={() => this.slider.slickPrev()}
                />
              </div>
            ))}
          </Slider>
          <div>
            <Footer activeToken={activeToken} event={event} redemptionAvailability={redemptionAvailability} tmOrderUrl={tmOrderUrl}/>
          </div>
        </div>
      </section>
    );
  }
}

export default withTranslation('translation')(Tickets);
