import React, { memo } from 'react';
import { withTranslation } from 'react-i18next';
import { Text } from '@ticketmaster/aurora';

import { T } from '@/helpers/i18n';

import './item.scss';

type Props = {
  title: string;
  data: any;
  children?: any;
} & T;

const InfoItem = ({ t, title = '', data = [], children }: Props) => {
  if (!Array.isArray(data)) {
    data = [data];
  }
  if (!data.length && !children) {
    return null;
  }
  data = data.filter((item: any) => !!item); // item exists

  return (
    <div className="info-item">
      {(children || data.length) && <Text weight="semiBold">{t(title)}</Text>}

      {data.map((text: string, key: number) => (
        <Text key={key} style={{ wordBreak: 'break-word' }}>
          {text}
        </Text>
      ))}
      {!!children && children}

      <hr className="divider"/>
    </div>
  );
};

export default withTranslation('translation')(memo(InfoItem));
