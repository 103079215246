import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { DeviceSizeProvider, DrawerProvider } from '@ticketmaster/aurora';
import { ThemeProvider } from 'styled-components';

import * as serviceWorker from './serviceWorker';
import i18next from './helpers/i18n/index';
import Root from './root/root';

import './index.scss';

const root = document.getElementById('root');
if (root) {
  ReactDOM.render(
    <ThemeProvider theme={{ themeName: 'tm' }}>
      <DrawerProvider>
        <DeviceSizeProvider cssOnly>
          <I18nextProvider i18n={i18next}>
            <Root/>
          </I18nextProvider>
        </DeviceSizeProvider>
      </DrawerProvider>
    </ThemeProvider>,
    root
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
