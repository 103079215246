// @ts-nocheck
export const getExpandedText = (config) => {
  if (!config.content) return;
  return config.expandedText || 'BANNER.SHOW_LESS';
};

export const getCollapsedText = (config) => {
  if (!config.content) return;
  return config.expandedText || 'BANNER.SHOW_MORE';
};
