// @ts-nocheck
import { CloseIcon } from '@ticketmaster/aurora';
import React from 'react';
import { VOIDED_TEXT_COLOR_HEX } from '@/wallet/wallet.constants';

const VoidedDateAndLocation = () => (
  <div id={"voided-date-and-location"}>
    <CloseIcon color={ VOIDED_TEXT_COLOR_HEX } style={{width: "100%", height: 30, textAlign: "center"}} />
  </div>
);

export default VoidedDateAndLocation;