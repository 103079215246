// @ts-nocheck

import React, { Component, memo } from 'react';
import { Text } from '@ticketmaster/aurora';
import { withTranslation } from 'react-i18next';
import mobileLogo from './images/mobile.png';

import './desktop.scss';
import { RootContext, StateContext } from '../root/root.provider';
import { getEnvelope, redeemTickets } from '../wallet/envelope/envelope.service';
import walletBannerOpts from '../wallet/wallet.banners';
import { getRedemptionReasonByCode } from '../wallet/manage/redemption/redemptionDisabledReasons';
import Banner from '../partials/banner/banner';
import Spinner from '../partials/spinner/spinner';
import { T } from '../helpers/i18n';

type State = {
  loading: boolean;
  redirection: boolean;
};

class Desktop extends Component<T, State> {
  static contextType: React.Context<StateContext> = RootContext;
  token: string = window.location.pathname.substring(1);

  state: State = {
    loading: true,
    redirection: false
  };

  async componentDidMount() {
    if (this.token) {
      try {
        const envelopeResponse = await getEnvelope(this.token);

        if (envelopeResponse.data?.redirectToRedemptionOnDesktop) {
          if (envelopeResponse.data?.redemptionAvailability?.redemptionDisabled) {
            this.context.showBanner(walletBannerOpts.redemptionError(
              getRedemptionReasonByCode(envelopeResponse.data?.redemptionAvailability.reason))
            );
          } else {
            let error = await redeemTickets(envelopeResponse.data.activeToken);
            if (error) {
              this.context.showBanner(error);
            } else {
              this.setState({ redirection: true });
            }
          }
        }
      } catch (err: any) {
        if (err.validate?.status === 'alreadyRedeemed') {
          window.location.href = err.validate.tmOrdersUrl;
          this.setState({ redirection: true });
        }
      }
    }
    this.setState({ loading: false });
  }

  render() {
    const { t } = this.props;
    const { banner } = this.context.state;

    return (
      <div>
        <Spinner isShown={this.state.loading || this.state.redirection}/>
        {!this.state.loading &&
          !this.state.redirection &&
          (
            <div className="desktop">
              <Banner config={banner}/>
              <img className="mobile-logo" src={mobileLogo} alt="Mobile only"/>
              <Text weight="semiBold" className="heading-text">
                {t('DESKTOP.TITLE')}
              </Text>
              <Text weight="semiBold" className="main-text" size="kilo">
                {t('DESKTOP.TEXT')}
              </Text>
            </div>
          )}
      </div>
    );
  }
}

export default withTranslation('translation')(memo(Desktop));
