// @ts-nocheck
import bwipjs from 'bwip-js';

const getBarcodeType = (type) => (type === 'barcode' ? 'qrcode' : type);

const getImage = ({ barcodeType, barcodeValue, isSecureEntry }) => {
  try {
    if (!barcodeValue) return null;
    if (isSecureEntry) barcodeType = 'pdf417';

    const canvas = document.createElement('canvas');
    bwipjs.toCanvas(canvas, {
      bcid: getBarcodeType(barcodeType),
      text: barcodeValue,
      scale: 10,
      includetext: true,
      textxalign: 'center',
    });
    const dataUri = canvas.toDataURL('image/png');
    canvas.remove();
    return dataUri;
  } catch (err) {
    return null;
  }
};

export { getImage };
