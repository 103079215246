import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { Text } from '@ticketmaster/aurora';

import './spinner.scss';
import { T } from '@/helpers/i18n';

type Props = {
  isShown: boolean;
} & T;

const Spinner = ({ t, isShown }: Props) => {
  return (
    <Fragment>
      {isShown && (
        <div className="spinner">
          <Text>{t('BASE.LOADING')}</Text>
        </div>
      )}
    </Fragment>
  );
};

export default withTranslation('translation')(Spinner);
