// @ts-nocheck
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import './footer.scss';
import { T } from '@/helpers/i18n';
import ManageTicketButton from './manageTicketButton/manageTicketButton';
import { RedemptionDisabledReasons } from '@/wallet/manage/redemption/redemptionDisabledReasons.ts';

type Props = {
  activeToken: string;
  event: EventModel;
  redemptionAvailability: RedemptionAvailabilityModel;
  tmOrderUrl: string;
} & T;
type State = {};

class Footer extends Component<Props, State> {

  render() {
    const { activeToken, event, redemptionAvailability, tmOrderUrl } = this.props;
    return (
      redemptionAvailability.reason !== RedemptionDisabledReasons.FORCED && (<section className="ticket-footer">
        <ManageTicketButton activeToken={activeToken} event={event} redemptionAvailability={redemptionAvailability} tmOrderUrl={tmOrderUrl}/>
      </section>)
    );
  }
}

export default withTranslation('translation')(Footer);
