// @ts-nocheck

import React, { Fragment } from 'react';

import './root.scss';

import Header from '../header/header.tsx';
import Wallet from '../wallet/wallet.tsx';
import Desktop from '../desktop/desktop.tsx';
import { updateTitle } from '../helpers/other/strings';
import RootProvider from './root.provider.tsx';
import { isMobile } from '../helpers/device';

const Root = () => {
  updateTitle();

  const isMobileDevice: boolean = isMobile.any();

  return (
    <RootProvider>
      {!isMobileDevice && <Desktop/>}

      {isMobileDevice && (
        <Fragment>
          <Header/>
          <Wallet/>
        </Fragment>
      )}
    </RootProvider>
  );
};

export default Root;
