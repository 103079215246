// @ts-nocheck
import React, { memo } from 'react';
import { withTranslation } from 'react-i18next';

import TicketField from './field/field.tsx';
import { GENERAL_ADMISSION, GENERAL_ADMISSION_REF } from './field/field.constants';

import { T } from '@/helpers/i18n';

import './fields.scss';
import { getFieldBackgroundColor, getTextFontColor } from '@/helpers/ticketColors/ticketColorsHelper';

type Props = {
  ticket: TicketModel;
} & T;

const TicketFields = memo(({ t, ticket }: Props) => {
  const fieldsBackgroundColor = getFieldBackgroundColor(ticket);

  return (
    <section className="ticket-fields" style={{ backgroundColor: fieldsBackgroundColor }}>
      {ticket.isGA && (ticket.showGARef ?
        <TicketField title={GENERAL_ADMISSION_REF} value={[ticket.section, ticket.row, ticket.seat]} textFontColor={getTextFontColor(ticket)}/>
        : <TicketField title={GENERAL_ADMISSION} value={ticket.section} textFontColor={getTextFontColor(ticket)}/>)}
      {!ticket.isGA &&
        ['section', 'row', 'seat'].map((field, index) => {
          return <TicketField key={index} title={field} value={ticket[field]} textFontColor={getTextFontColor(ticket)}/>;
        })}
    </section>
  );
});

export default withTranslation('translation')(TicketFields);
