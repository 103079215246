// @ts-nocheck

import { withTranslation } from 'react-i18next';
import React, { Fragment, memo } from 'react';
import RET from './RET/RET';
import BarcodeImage from './image/image';

type Props = {
  ticket: TicketModel;
  index: number;
}

const RotatingOrStaticBarcode = ({ ticket, index }: Props) => {
  const { isSecureEntry, barcode, rotatingEntryToken, renderType } = ticket;

  return (
    <Fragment>
      {isSecureEntry && rotatingEntryToken && <RET token={rotatingEntryToken} id={`ret${index}BarcodeId`}/>}
      {(!isSecureEntry || !rotatingEntryToken) && (
        <BarcodeImage barcodeValue={barcode} barcodeType={renderType} isSecureEntry={isSecureEntry}/>
      )}
    </Fragment>
  );
};

export default withTranslation('translation')(memo(RotatingOrStaticBarcode));