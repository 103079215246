import { withTranslation } from 'react-i18next';
import { Text } from '@ticketmaster/aurora';

import { T } from '@/helpers/i18n';
import { formatMoney } from '@/helpers/moneyFormatter/moneyFormatter';

import './item.scss';
import React from 'react';

type Props = {
  title: string;
  feeBreakdown: [];
  singleFee: number;
  locale: string;
  currency: string;
} & T;

type Fee = {
  name: string;
  value: number;
}

const Fees = ({ title, feeBreakdown = [], singleFee, locale, currency }: Props) => {
  if (feeBreakdown.length === 0) {
    return (
      <div id="fees">
        <Text>{`${title}: ${formatMoney(locale, currency, singleFee)}`}</Text>
      </div>
    );
  }
  return (
    <div id="fees">
      <Text>{title + ':'}</Text>
      {feeBreakdown.map((fee: Fee, key) => (
        <Text key={key} style={{ wordBreak: 'break-word' }}>
          &nbsp;&nbsp;{'- ' + fee.name + ' : ' + formatMoney(locale, currency, fee.value)}
        </Text>
      ))}
    </div>
  );
};

export default withTranslation('translation')(Fees);