import { EventModel } from './eventModel';
import { TicketModel } from './ticketModel';

export type EnvelopeModel = {
  tickets: TicketModel[];
  userLanguage: string;
  event: EventModel;
  activeToken: string;
  tmOrderUrl: string;
  redemptionAvailability: boolean;
  otherFees: number;
  orderId: string | null;
  locatorId: string | null;
}

export type RedemptionAvailabilityModel = {
  redemptionDisabled: boolean;
  reason: string;
}

export function resolveReferenceId(envelope: EnvelopeModel): string | null {
  return envelope.locatorId || envelope.orderId;
}
