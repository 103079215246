export type EventModel = {
  id: number;
  dateRange: string;
  startLocalDate: string;
  startLocalTime: string;
  venue: VenueModel;
  title: string;
  subSystemSource: string;
}

export type VenueModel = {
  title: string;
  countryCode: string;
  address: string;
  city: string;
  stateCode: string;
  postalCode: string;
}

export function getFormattedVenueAddress(venue: VenueModel): string {
  return [venue.address, venue.city, venue.stateCode, venue.postalCode].filter(field => field && field.trim()).join(', ');
}