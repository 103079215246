// @ts-nocheck
import axios from 'axios';

import { API_URL } from '@/root/root.constants';
import { noResponseInterceptor } from './interceptors.ts';
import { isMobile } from '../device';

const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
    'presto-client-device': isMobile.any() ? 'Mobile' : 'Other',
  },
});

api.interceptors.response.use(null, noResponseInterceptor);

export default api;
